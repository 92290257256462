
























import Vue from 'vue';
import { RootState } from '@/store';

interface VuetifyItem {
	text: string | number | object;
	value: string | number | object;
	disabled: boolean;
	divider: boolean;
	header: string;
	type: string;
}

interface ItemType {
	text: string;
	value: {
		id: number | string;
		type: 'level' | 'file' | 'fileCategory' | 'case';
	};
}

export default Vue.extend({
	data() {
		return {
			chips: [] as VuetifyItem[]
		};
	},

	methods: {
		remove(item: VuetifyItem) {
			this.chips.splice(this.chips.indexOf(item), 1);
			this.chips = [...this.chips];
			this.$store.commit(
				'filterSelection',
				this.chips.map(({ value, type }) => ({ value, type }))
			);
		},
		updateFilterSelection(selection: VuetifyItem[]) {
			this.$store.commit(
				'filterSelection',
				selection.map(({ value, type }) => ({ value, type }))
			);
		}
	},

	computed: {
		filterSelection(): (ItemType | { header: string })[] {
			return [
				{ header: 'Files' },
				...this.fileNames,
				{ header: 'File Categories' },
				...this.fileCategories,
				{ header: 'Cases' },
				...this.cases,
				{ header: 'Show only' },
				{ text: 'Categories', value: { id: 'categories', type: 'level' } }
			];
		},
		fileNames(): ItemType[] {
			return (this.$store.state as RootState).projectCurrent.sources.data
				.filter((v) => v.status === 1)
				.map((v) => ({
					text: v.name,
					value: { id: v.id, type: 'file' }
				}));
		},
		fileCategories(): ItemType[] {
			return (this.$store.state as RootState).projectCurrent.fileCategories.data
				.filter((v) => v.status === 1)
				.map((v) => ({
					text: v.name,
					value: { id: v.catid, type: 'fileCategory' }
				}));
		},
		cases(): ItemType[] {
			return (this.$store.state as RootState).projectCurrent.cases.data
				.filter((v) => v.status === 1)
				.map((v) => ({
					text: v.name,
					value: { id: v.id, type: 'case' }
				}));
		}
	}
});
