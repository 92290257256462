


















import Vue from 'vue';
import Plot from '@/components/plot.vue';
import VFilter from '@/components/filter.vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
	name: 'Graph',
	components: { VFilter, Plot },
	computed: mapGetters(['graphDataFiltered']),
	methods: mapActions(['downloadGraph'])
});
